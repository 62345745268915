<template>
  <div class="hello">
    <div class="header py-10">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="relative z-50 flex justify-between">
          <div class="flex items-center md:gap-x-12">
            <a href="#">
              <img class="h-7 w-auto" src="@/assets/images/logo-text.png" />
            </a>
            <div class="hidden md:flex md:gap-x-6">
              <div
                class="inline-block rounded-lg py-1 px-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                @click="goAnchor('#product')"
              >
                产品 
              </div>
              <div
                class="inline-block rounded-lg py-1 px-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                @click="goAnchor('#features')"
              >
                特色
              </div>
              <div
                class="inline-block rounded-lg py-1 px-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                @click="goAnchor('#comments')"
              >
                评论
              </div>
              <div
                class="inline-block rounded-lg py-1 px-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                @click="goAnchor('#about')"
              >
                关于我们
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="page1 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pt-20 pb-20 text-center lg:pt-32 pb-32"
    >
      <h1
        class="mx-auto max-w-4xl font-display text-5xl font-medium tracking-tight text-gray-900 sm:text-7xl"
      >
        轻盈 
        <span class="relative whitespace-nowrap text-purple-700">
          <svg
            aria-hidden="true"
            viewBox="0 0 418 42"
            class="absolute top-2/3 left-0 h-[0.58em] w-full fill-purple-300/70"
            preserveAspectRatio="none"
          >
            <path
              d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"
            ></path>
          </svg>
          <span class="relative">、强大的查词软件</span>
        </span>
        <div class='mt-8'>
         学习英语的伙伴
        </div>
      </h1>
      <p class="mx-auto mt-8 max-w-2xl text-lg tracking-tight text-gray-600">
        内置500,000+常用词组，日常例句，让你加倍理解单词的真实使用场景，达到快速掌握活学活用的效果
      </p>
      <div class="mt-10 flex justify-center gap-x-6">
        <a
          class="group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-gray-900 text-white hover:bg-gray-700 hover:text-gray-100 active:bg-gray-800 active:text-gray-300 focus-visible:outline-gray-900"
          href="https://fanyiniao.oss-cn-hangzhou.aliyuncs.com/soft/fanyiniao_win_1.1.zip"
          target="_blank"
        >
          下载 Windows 版本
        </a>
        <a
          class="group inline-flex ring-1 items-center justify-center rounded-full py-2 px-4 text-sm focus:outline-none ring-gray-200 text-gray-700 hover:text-gray-900 hover:ring-gray-300 active:bg-gray-100 active:text-gray-600 focus-visible:outline-purple-600 focus-visible:ring-gray-300"
          target="_blank"
          href="https://apps.apple.com/us/app/%E7%BF%BB%E8%AF%91%E9%B8%9F/id6447509379"
        >
          下载 MacOS 版本
        </a>
      </div>
    </div>
    <div class="page2 bg-gray-50 pt-20 pb-28 sm:py-32 flex-col" id='product'>
      <div class='text-center mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
        支持单词<span class='text-purple-700'>复制</span>取词，还能整句翻译句子
      </div>
      <div class='mx-auto text-center px-1 mt-6 text-lg leading-8 text-gray-600 max-w-2xl'>
        一个可以随时从屏幕<span class='text-purple-700'>复制</span>翻译的软件工具，高效、碎片化学习英语的伴侣
      </div>

    <ul class="flex justify-center flex-wrap mt-8 text-sm font-medium text-center text-gray-500 border-b border-gray-200">
        <li class="mr-2 cursor-pointer" @click='changePic(0)'>
            <span class="inline-block p-4 rounded-t-lg hover:text-gray-600" :class="{'text-purple-600':selectIndex==0, 'bg-gray-200':selectIndex==0}">复制自动翻译</span>
        </li>
        <li class="mr-2 cursor-pointer" @click="changePic(1)">
            <span  class="inline-block p-4 rounded-t-lg hover:text-gray-600" :class="{'text-purple-600':selectIndex==1, 'bg-gray-200':selectIndex==1}">单词详情</span>
        </li>
        <li class="mr-2 cursor-pointer" @click="changePic(2)">
            <span class="inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-100" :class="{'text-purple-600':selectIndex==2, 'bg-gray-200':selectIndex==2}">翻译句子</span>
        </li>
        <li class="mr-2 cursor-pointer" @click="changePic(3)">
            <span class="inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-100" :class="{'text-purple-600':selectIndex==3, 'bg-gray-200':selectIndex==3}">导出单词</span>
        </li>
    </ul>
      <img  class='px-1 mx-auto mt-16 rounded-s-lg w-12/12  sm:mt-20 lg:mt-16 lg:w-7/12 lg:max-w-4xl' :class="{block:selectIndex==0, hidden:selectIndex != 0}"  src="@/assets/gif/01.gif">
      <img  class='px-1 mx-auto mt-16 rounded-s-lg w-12/12  sm:mt-20 lg:mt-16 lg:w-4/12 lg:max-w-2xl' :class="{block:selectIndex==1, hidden:selectIndex != 1}"  src="@/assets/gif/02.gif">
      <img  class='px-1 mx-auto mt-16 rounded-s-lg w-12/12  sm:mt-20 lg:mt-16 lg:w-4/12 lg:max-w-2xl' :class="{block:selectIndex==2, hidden:selectIndex != 2}"  src="@/assets/gif/03.gif">
      <img  class='px-1 mx-auto mt-16 rounded-s-lg w-12/12  sm:mt-20 lg:mt-16 lg:w-4/12 lg:max-w-2xl' :class="{block:selectIndex==3, hidden:selectIndex != 3}"   src="@/assets/gif/04.gif">
    </div>

    <div class="bg-white py-24 sm:py-32" id='features'>
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl lg:text-center">
          <p
            class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
          >
            学习英语最好的时机就是现在
          </p>
          <p class="mt-6 text-lg leading-8 text-gray-600">
            学习一门外语需要大量的时间和精力，而且很多时候，我们都是在碎片化的时间里学习英语，这时候，我们需要一个高效的工具来帮助我们学习。
          </p>
        </div>
        <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl
            class="grid max-w-xl grid-cols-1 gap-y-10 gap-x-8 lg:max-w-none lg:grid-cols-2 lg:gap-y-16"
          >
            <div class="relative pl-16">
              <dt class="text-base font-semibold leading-7 text-gray-900">
                <div
                  class="absolute top-0 left-0 flex h-10 w-10 items-center justify-center rounded-lg bg-purple-600"
                >
                  <svg
                    class="h-6 w-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                    />
                  </svg>
                </div>
                轻盈、强大的查词软件
              </dt>
              <dd class="mt-2 text-base leading-7 text-gray-600">
                 复制即可实时查询单词词义，支持英音、美音两种主流发音。
                内置500,000常用词组，让你加倍理解单词的真实使用场景，达到快速掌握活学活用的效果

              </dd>
            </div>

            <div class="relative pl-16">
              <dt class="text-base font-semibold leading-7 text-gray-900">
                <div
                  class="absolute top-0 left-0 flex h-10 w-10 items-center justify-center rounded-lg bg-purple-600"
                >
                  <svg
                    class="h-6 w-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                    />
                  </svg>
                </div>
                支持屏幕复制取词、自动翻译词义
              </dt>
              <dd class="mt-2 text-base leading-7 text-gray-600">
                默认后台隐藏，不打扰日常工作
              一个可以随时从屏幕复制翻译的软件工具，高效、碎片化学习英语的伴侣
              支持单词复制取词，还能整句翻译句子
              </dd>
            </div>

            <div class="relative pl-16">
              <dt class="text-base font-semibold leading-7 text-gray-900">
                <div
                  class="absolute top-0 left-0 flex h-10 w-10 items-center justify-center rounded-lg bg-purple-600"
                >
                  <svg
                    class="h-6 w-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                    />
                  </svg>
                </div>
                高效整理专属生词本
              </dt>
              <dd class="mt-2 text-base leading-7 text-gray-600">
                 不仅让用户查看历史单词以加深记忆，还提供了生词本导出为csv格式的功能，方便在其他主流刷词软件上使用。便于随时随地进行学习。
              </dd>
            </div>

            <div class="relative pl-16">
              <dt class="text-base font-semibold leading-7 text-gray-900">
                <div
                  class="absolute top-0 left-0 flex h-10 w-10 items-center justify-center rounded-lg bg-purple-600"
                >
                  <svg
                    class="h-6 w-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M7.864 4.243A7.5 7.5 0 0119.5 10.5c0 2.92-.556 5.709-1.568 8.268M5.742 6.364A7.465 7.465 0 004.5 10.5a7.464 7.464 0 01-1.15 3.993m1.989 3.559A11.209 11.209 0 008.25 10.5a3.75 3.75 0 117.5 0c0 .527-.021 1.049-.064 1.565M12 10.5a14.94 14.94 0 01-3.6 9.75m6.633-4.596a18.666 18.666 0 01-2.485 5.33"
                    />
                  </svg>
                </div>
                词组、例句、联想词
              </dt>
              <dd class="mt-2 text-base leading-7 text-gray-600">
                我们的开发团队深知用户对于单词词义的理解有多么重要，因此我们提供了常见词组和日常用语的例句，以便用户更好地了解单词在实际场景中的应用。
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
    <section
      id="comments"
      aria-label="What our customers are saying"
      class="bg-slate-50 py-20 sm:py-32"
    >
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="mx-auto max-w-2xl md:text-center">
          <h2
            class="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl"
          >
            学习从来不是简单事情，我们会一直陪伴你
          </h2>
          <p class="mt-4 text-lg tracking-tight text-slate-700">
            "Success is not final, failure is not fatal: it is the courage to continue that counts." - Winston Churchill
          </p>
        </div>
        <ul
          role="list"
          class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 sm:gap-8 lg:mt-20 lg:max-w-none lg:grid-cols-3"
        >
          <li>
            <ul role="list" class="flex flex-col gap-y-6 sm:gap-y-8">
              <li>
                <figure
                  class="relative rounded-2xl bg-white p-6 shadow-xl shadow-slate-900/10"
                >
                  <svg
                    aria-hidden="true"
                    width="105"
                    height="78"
                    class="absolute top-6 left-6 fill-slate-100"
                  >
                    <path
                      d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z"
                    ></path>
                  </svg>
                  <blockquote class="relative">
                    <p class="text-lg tracking-tight text-slate-900">
                      这个英语查词app真的太好用了！它不仅可以查找单词的定义，而且还提供了例句和发音，这对于正在学英语的我来说非常有帮助。另外，还可以导出生词本，可以用到别的app上，太方便专项复习了！
                    </p>
                  </blockquote>
                  <figcaption
                    class="relative mt-6 flex items-center justify-between border-t border-slate-100 pt-6"
                  >
                    <div>
                      <div class="font-display text-base text-slate-900">
                        星之胖胖丁
                      </div>
                      <div class="mt-1 text-sm text-slate-500">
                        某高校大学生
                      </div>
                    </div>
                    <div class="overflow-hidden rounded-full bg-slate-50">
                      <img
                        src="@/assets/images/avatar-1.jpg"
                        class="h-14 w-14 object-cover"
                        loading="lazy"
                        style="color: transparent"
                      />
                    </div>
                  </figcaption>
                </figure>
              </li>
              <li>
                <figure
                  class="relative rounded-2xl bg-white p-6 shadow-xl shadow-slate-900/10"
                >
                  <svg
                    aria-hidden="true"
                    width="105"
                    height="78"
                    class="absolute top-6 left-6 fill-slate-100"
                  >
                    <path
                      d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z"
                    ></path>
                  </svg>
                  <blockquote class="relative">
                    <p class="text-lg tracking-tight text-slate-900">
                      在职业生涯中，英语是提示个人技能的必不可少的一部分。这个英语查词app非常专业，很多专业的高级生僻词都可以查询到，另外，它还提供了一些在线翻译和语音翻译的功能，方便我与国外客户进行交流。
                    </p>
                  </blockquote>
                  <figcaption
                    class="relative mt-6 flex items-center justify-between border-t border-slate-100 pt-6"
                  >
                    <div>
                      <div class="font-display text-base text-slate-900">
                        Amy Hahn
                      </div>
                      <div class="mt-1 text-sm text-slate-500">
                        某互联网企业高管
                      </div>
                    </div>
                    <div class="overflow-hidden rounded-full bg-slate-50">
                      <img
                        src="@/assets/images/avatar-4.jpg"
                        width="56"
                        height="56"
                        decoding="async"
                        data-nimg="1"
                        class="h-14 w-14 object-cover"
                        loading="lazy"
                        style="color: transparent"
                      />
                    </div>
                  </figcaption>
                </figure>
              </li>
            </ul>
          </li>
          <li>
            <ul role="list" class="flex flex-col gap-y-6 sm:gap-y-8">
              <li>
                <figure
                  class="relative rounded-2xl bg-white p-6 shadow-xl shadow-slate-900/10"
                >
                  <svg
                    aria-hidden="true"
                    width="105"
                    height="78"
                    class="absolute top-6 left-6 fill-slate-100"
                  >
                    <path
                      d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z"
                    ></path>
                  </svg>
                  <blockquote class="relative">
                    <p class="text-lg tracking-tight text-slate-900">
                       作为一名外贸行业从业者，我需要经常使用英语来与国外的客户沟通。翻译鸟非常实用，可以帮我快速查找单词的地道用法，让我在工作中更加得心应手。客户再也没疑惑过我的中式英语
                    </p>
                  </blockquote>
                  <figcaption
                    class="relative mt-6 flex items-center justify-between border-t border-slate-100 pt-6"
                  >
                    <div>
                      <div class="font-display text-base text-slate-900">
                        Johney张
                      </div>
                      <div class="mt-1 text-sm text-slate-500">
                        某外企白领
                      </div>
                    </div>
                    <div class="overflow-hidden rounded-full bg-slate-50">
                      <img
                        src="@/assets/images/avatar-2.jpg"
                        width="56"
                        height="56"
                        class="h-14 w-14 object-cover"
                        loading="lazy"
                        style="color: transparent"
                      />
                    </div>
                  </figcaption>
                </figure>
              </li>
              <li>
                <figure
                  class="relative rounded-2xl bg-white p-6 shadow-xl shadow-slate-900/10"
                >
                  <svg
                    aria-hidden="true"
                    width="105"
                    height="78"
                    class="absolute top-6 left-6 fill-slate-100"
                  >
                    <path
                      d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z"
                    ></path>
                  </svg>
                  <blockquote class="relative">
                    <p class="text-lg tracking-tight text-slate-900">
                      作为一名刚刚踏入职场的新人，我需要学习很多新的专业术语和词汇。翻译鸟提供了很多专业的词汇和场景用法，让我更加容易理解这些新的概念。同时，它还提供了一些关联词汇，让我更加有条理地学习英语。
                    </p>
                  </blockquote>
                  <figcaption
                    class="relative mt-6 flex items-center justify-between border-t border-slate-100 pt-6"
                  >
                    <div>
                      <div class="font-display text-base text-slate-900">
                        团子有点肥
                      </div>
                      <div class="mt-1 text-sm text-slate-500">
                        某高校应届生
                      </div>
                    </div>
                    <div class="overflow-hidden rounded-full bg-slate-50">
                      <img
                        src="@/assets/images/avatar-5.jpg"
                        width="56"
                        height="56"
                        decoding="async"
                        data-nimg="1"
                        class="h-14 w-14 object-cover"
                        loading="lazy"
                        style="color: transparent"
                      />
                    </div>
                  </figcaption>
                </figure>
              </li>
            </ul>
          </li>
          <li>
            <ul role="list" class="flex flex-col gap-y-6 sm:gap-y-8">
              <li>
                <figure
                  class="relative rounded-2xl bg-white p-6 shadow-xl shadow-slate-900/10"
                >
                  <svg
                    aria-hidden="true"
                    width="105"
                    height="78"
                    class="absolute top-6 left-6 fill-slate-100"
                  >
                    <path
                      d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z"
                    ></path>
                  </svg>
                  <blockquote class="relative">
                    <p class="text-lg tracking-tight text-slate-900">
                      在回归家庭3年的期间我一直在坚持积累单词，随时准备重新回到职场，我非常推荐生词本功能，可以反复查看、记忆、背诵。我的心得就是任何外语学习单词量都是最重要的。
                    </p>
                  </blockquote>
                  <figcaption
                    class="relative mt-6 flex items-center justify-between border-t border-slate-100 pt-6"
                  >
                    <div>
                      <div class="font-display text-base text-slate-900">
                        echo
                      </div>
                      <div class="mt-1 text-sm text-slate-500">
                        家庭主妇
                      </div>
                    </div>
                    <div class="overflow-hidden rounded-full bg-slate-50">
                      <img
                        src="@/assets/images/avatar-3.jpg"
                        width="56"
                        height="56"
                        decoding="async"
                        data-nimg="1"
                        class="h-14 w-14 object-cover"
                        loading="lazy"
                        style="color: transparent"
                      />
                    </div>
                  </figcaption>
                </figure>
              </li>
              <li>
                <figure
                  class="relative rounded-2xl bg-white p-6 shadow-xl shadow-slate-900/10"
                >
                  <svg
                    aria-hidden="true"
                    width="105"
                    height="78"
                    class="absolute top-6 left-6 fill-slate-100"
                  >
                    <path
                      d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z"
                    ></path>
                  </svg>
                  <blockquote class="relative">
                    <p class="text-lg tracking-tight text-slate-900">
                      我喜欢阅读英文文章和听英文歌曲。翻译鸟提供了英音和美音的功能，可以随时纠正我的发音，让我更加深入地了解英文歌曲。某种程度上，我现在的发音比考雅思时好多了。
                    </p>
                  </blockquote>
                  <figcaption
                    class="relative mt-6 flex items-center justify-between border-t border-slate-100 pt-6"
                  >
                    <div>
                      <div class="font-display text-base text-slate-900">
                        一只妮
                      </div>
                      <div class="mt-1 text-sm text-slate-500">
                        某英语爱好者
                      </div>
                    </div>
                    <div class="overflow-hidden rounded-full bg-slate-50">
                      <img
                        src="@/assets/images/avatar-6.jpg"
                        width="56"
                        height="56"
                        decoding="async"
                        data-nimg="1"
                        class="h-14 w-14 object-cover"
                        loading="lazy"
                        style="color: transparent"
                      />
                    </div>
                  </figcaption>
                </figure>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </section>
    <footer class="bg-slate-50">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="py-16">
          <div class="flex justify-center">
            <img class="h-7 w-auto" src="@/assets/images/logo-text.png" />
          </div>
          <nav class="mt-10 text-sm" aria-label="quick links">
            <div class="-my-1 flex justify-center gap-x-6">
              <a
                class="cursor-pointer inline-block rounded-lg py-1 px-2 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900"
                @click="goAnchor('#product')"
                >产品</a
              ><a
                class="cursor-pointer inline-block rounded-lg py-1 px-2 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900"
                @click="goAnchor('#features')"
                >特色</a
              ><a
                class="cursor-pointer inline-block rounded-lg py-1 px-2 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900"
                @click="goAnchor('#comments')"
                >评价</a
              >
            </div>
          </nav>
        </div>
        <div
          class="flex flex-col items-center border-t border-slate-400/10 py-10 sm:flex-row-reverse sm:justify-between"
          id='about'
        >
          <div class="flex text-sm text-slate-500 sm:mt-0">
            <a href='https://beian.miit.gov.cn/' target="_blank">
              浙ICP备14037565号-20
            </a>
          </div>
          <p class="mt-6 text-sm text-slate-500 sm:mt-0">
            Copyright ©
            2023
            Like To Do. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      selectIndex: 0,
    };
  },
  methods: {
    changePic(index){
      this.selectIndex = index
    },
    goAnchor(selector) {/*参数selector是id选择器（#anchor14）*/
      document.querySelector(selector).scrollIntoView({
        behavior: "smooth"
      });
    },
  },
};
</script>

<style lang='scss' scoped>
body{
  font-family: "SF Pro SC","SF Pro Text","SF Pro Icons","PingFang SC","Helvetica Neue","Helvetica","Arial",sans-serif !important;
}
</style>
